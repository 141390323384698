import React, { useState } from "react";
import Layout from "../../../Layout";
import Basic from "./Basic";
import CargoInformation from "./CargoInformation";
import ShipInformation from "./ShipInformation";

const data = [
  "Basic",
  "Cargo Information",
  "Ports Information",
  "Ship Information",
  "Clauses",
];

const OfferCargo = () => {
  const [activeComponent, setActiveComponent] = useState("Basic");

  const returnComponent = () => {
    if (activeComponent === "Basic") {
      return <Basic />;
    } else if (activeComponent === "Cargo Information") {
      return <CargoInformation />;
    } else if (activeComponent === "Ship Information") {
      return <ShipInformation />;
    }
  };

  return (
    <>
      <Layout title="My Offers">
        <div className="grid grid-cols-1 gap-[15px]">
          <div className="w-full relative flex justify-center items-center py-2 px-5 text-3xl text-white rounded overflow-hidden">
            <div className="w-full absolute h-full top-0 left-0 right-0 bottom-0 bg-white opacity-20"></div>
            Offer Cargo
          </div>
          <div className="grid grid-cols-5 relative gap-2">
            {data.map((item, i) => (
              <button
                key={i}
                className={`bg-primaryColor text-white py-3 px-8 rounded mr-2 w-full ${
                  activeComponent === item ? "border" : ""
                }`}
                onClick={() => setActiveComponent(item)}
              >
                {item}
              </button>
            ))}
          </div>

          <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] overflow-hidden overflow-y-scroll">
            <p className="text-white text-xl">
              Please be advised that we have below enquiry which kindly propose
              suitable vessel with Owners best firm offer:
            </p>
            {returnComponent()}
          </div>

          <div className="w-full text-white flex justify-between">
            <button className="py-2 px-5 bg-primaryColor rounded mr-2 w-[80%]">
              Back
            </button>
            <button className="py-2 px-5 bg-primaryColor rounded w-[20%]">
              Next
            </button>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default OfferCargo;
