import React, { useState } from "react";
import Layout from "../../../Layout";
import Sent from "./Sent";
import Recieved from "./Recieved";

const MyOffers = () => {
  const [activeComponent, setActiveComponent] = useState("sent");

  return (
    <>
      <Layout title="My Offers">
        <div className="grid grid-cols-1 gap-[15px]">
          <div className="w-full relative flex justify-center items-center py-2 px-5 text-3xl text-white rounded overflow-hidden">
            <div className="w-full absolute h-full top-0 left-0 right-0 bottom-0 bg-white opacity-20"></div>
            Offer Cargo
          </div>
          <div className="flex justify-between items-center relative">
            <div>
              <button
                className={`bg-primaryColor text-white py-3 px-8 rounded mr-2 ${
                  activeComponent === "sent" ? "border" : ""
                }`}
                onClick={() => setActiveComponent("sent")}
              >
                Sent
              </button>
              <button
                className={`bg-primaryColor text-white py-3 px-8 rounded mr-2 ${
                  activeComponent !== "sent" ? "border" : ""
                }`}
                onClick={() => setActiveComponent("recieved")}
              >
                Recieved
              </button>
            </div>
            <button className="bg-primaryColor text-white py-3 px-8 rounded">
              Offer
            </button>
          </div>
          {activeComponent === "sent" ? <Sent /> : <Recieved />}
          <div className="w-full text-white flex justify-between">
            <button className="py-2 px-5 bg-primaryColor rounded mr-2 w-[80%]">
              Back
            </button>
            <button className="py-2 px-5 bg-primaryColor rounded w-[20%]">
              Next
            </button>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MyOffers;
