import React from "react";
import Layout from "../../Layout";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import features from "../../../features.json";

const LiveMap = () => {
  return (
    <Layout title={"Live Map"}>
      <div className="w-full rounded border-primaryColor h-[calc(100vh_-_200px)] overflow-hidden overflow-y-scroll flex justify-center items-center bg-secondaryColor">
        <div className="w-[60%]">
          <ComposableMap>
            <Geographies geography={features}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography key={geo.rsmKey} geography={geo} />
                ))
              }
            </Geographies>
          </ComposableMap>
        </div>
      </div>
    </Layout>
  );
};

export default LiveMap;
