import React from "react";
import SelectInput from "../../../common/SelectInput";
import OnlySelect from "../../../common/OnlySelect";

const Label = ({ labelName }) => {
  return <p className="w-[200px] text-xl">{labelName}</p>;
};

const ShipInformation = () => {
  const data = [
    {
      label: "Ship TypeType",
      inputType: "select",
      width: "full",
    },
    {
      label: "Flag",
      inputType: "select",
      width: "full",
    },
    {
      label: "Deadweight",
      inputType: "select2",
      width: "full",
    },
    {
      label: "To",
      inputType: "select2",
      width: "full",
    },
    {
      label: "LOA",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Beam",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Hatch TypeType ",
      inputType: "select",
      width: "full",
    },
    {
      label: "Number of HoldsHold",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Draft",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Summer Draft",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Classification",
      inputType: "select",
      width: "full",
    },
    {
      label: "Age",
      inputType: "select",
      width: "full",
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-x-20 gap-y-5 content-start h-[430px] overflow-hidden overflow-y-scroll">
      {data.map((item, i) => (
        <div className="flex items-center text-white my-0 col-span-1" key={i}>
          <Label labelName={item.label} />
          <div className="flex justify-between items-center flex-1">
            <SelectInput
              fullWidth={item.inputType === "select2" ? false : true}
            />
            {item.inputType === "select2" && <OnlySelect />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShipInformation;
