import React from "react";

const TableBody = () => {
  const data = [
    {
      sr: "Sh.Is",
      inf: "Open",
      name: "MV MASTER",
      destination: "Kosichang, Thailand",
      quantity: "40,000 MT +/- 5%",
      laycan: "15 Dec 23 – 20 Dec",
      eta: "17 December 24",
      reciever_name: "Receiver Company 1",
      selling_term: "CIF",
    },
    {
      sr: "Sh.Is",
      inf: "Open",
      name: "MV MASTER",
      destination: "Kosichang, Thailand",
      quantity: "40,000 MT +/- 5%",
      laycan: "15 Dec 23 – 20 Dec",
      eta: "17 December 24",
      reciever_name: "Receiver Company 1",
      selling_term: "CIF",
    },
    {
      sr: "Sh.Is",
      inf: "Open",
      name: "MV MASTER",
      destination: "Kosichang, Thailand",
      quantity: "40,000 MT +/- 5%",
      laycan: "15 Dec 23 – 20 Dec",
      eta: "17 December 24",
      reciever_name: "Receiver Company 1",
      selling_term: "CIF",
    },
    {
      sr: "Sh.Is",
      inf: "Open",
      name: "MV MASTER",
      destination: "Kosichang, Thailand",
      quantity: "40,000 MT +/- 5%",
      laycan: "15 Dec 23 – 20 Dec",
      eta: "17 December 24",
      reciever_name: "Receiver Company 1",
      selling_term: "CIF",
    },
  ];

  const commonStyleForBody = `py-1 px-2 font-normal rounded`;

  return (
    <>
      {data.map((item, i) => (
        <div className="flex items-center w-full justify-between" key={i}>
          <div className="w-[4%] bg-primaryColor py-1 px-2 min-h-[26px] rounded">
            {item.sr}
          </div>
          <div className={`${commonStyleForBody} w-[5%] text-center`}>
            {item.inf && (
              <div className="bg-secondaryColor min-h-[26px] rounded">
                {item.inf}
              </div>
            )}
          </div>
          <div className={`${commonStyleForBody} w-[15%] text-left`}>
            {item.name}
          </div>
          <div className={`${commonStyleForBody} w-[15%] text-center`}>
            {item.destination}
          </div>
          <div className={`${commonStyleForBody} w-[10%] text-center`}>
            {item.quantity}
          </div>
          <div className={`${commonStyleForBody} w-[14%] text-center`}>
            {item.laycan}
          </div>
          <div
            id="shipVet"
            className={`${commonStyleForBody} w-[10%] text-center flex justify-center`}
          >
            {item.eta}
          </div>
          <div className={`${commonStyleForBody} w-[15%] text-secondaryColor`}>
            {item.reciever_name}
          </div>
          <div
            className={`${commonStyleForBody} w-[10%] text-center text-secondaryColor`}
          >
            {item.selling_term}
          </div>
        </div>
      ))}
    </>
  );
};

export default TableBody;
