import React from "react";

const SelectInput = ({ fullWidth }) => {
  return (
    <>
      <div
        className={`flex items-center h-[35px] ${
          fullWidth ? "w-full" : "w-max"
        }`}
      >
        <button className="w-[35px] h-full rounded bg-primaryColor text-white text-xl">
          +
        </button>
        <select
          className={`bg-black h-full text-secondaryColor outline-none text-xl text-center px-3 ${
            fullWidth ? "w-full" : "w-[250px]"
          }`}
        >
          <option>25,000</option>
        </select>
        <button className="w-[35px] h-full rounded bg-primaryColor text-white text-xl">
          -
        </button>
      </div>
    </>
  );
};

export default SelectInput;
