import React from "react";

const TableHeader = () => {
  const commonStyleForHead = `bg-primaryColor py-1 px-2 font-normal rounded`;
  return (
    <div className="flex items-center w-full justify-between mb-3">
      <div className="w-[4%]"></div>
      <div className={`${commonStyleForHead} w-[5%] text-center`}>Inf</div>
      <div className={`${commonStyleForHead} w-[15%] text-left`}>
        Vessel Name
      </div>
      <div className={`${commonStyleForHead} w-[15%] text-center`}>
        Destination
      </div>
      <div className={`${commonStyleForHead} w-[10%] text-center`}>
        Quantity
      </div>
      <div className={`${commonStyleForHead} w-[14%] text-center`}>Laycan</div>
      <div className={`${commonStyleForHead} w-[10%] text-center`}>ETA</div>
      <div className={`${commonStyleForHead} w-[15%] text-center`}>
        Receiver Name
      </div>
      <div className={`${commonStyleForHead} w-[10%] text-center`}>
        Selling Term
      </div>
    </div>
  );
};

export default TableHeader;
