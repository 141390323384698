import React from "react";
import Layout from "../../Layout";
import AllMessages from "./AllMessages";
import ReadMessage from "./ReadMessage";
import Navigations from "./Navigations";
import Buttons from "./Buttons";

const Home = () => {
  const data = [
    {
      date: "Today",
      messages: [
        "MV SEABEAST is arriving today to Loading port at 1600 hrs",
        "MV AFRICANBUMA has started discharging activities in Houston today at 1430 Hrstoday at 1430 Hrs",
        "MV RAIN Estimate arrival date is 12 July 2023 1200 Hrs",
      ],
    },
    {
      date: "Yesterday",
      messages: [
        "MV SEABEAST latest ETA to load port is today at 1600 hrs as per Master last updated. The ship will proceed to Holdsupdated. The ship will proceed to Holds inspection prior loading, it is planned to load 50,000 Tons +/-5%inspection prior loading, it is planned to load 50,000 Tons +/-5%",
        "Load Port Agent will keep posting updates",
      ],
    },
  ];

  return (
    <>
      <Layout title="Global Cargo Corporation (GCC)">
        <div className="">
          <Navigations />
          <div className="grid grid-cols-1 gap-[15px] w-full mt-[15px]">
            <AllMessages data={data} />
            <ReadMessage />
            <Buttons />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
