import React from "react";

const Card = () => {
  return (
    <div className="bg-primaryColor rounded p-10 text-white col-span-1 h-max text-center">
      <h3 className="text-3xl mb-3">Freight Estimation</h3>
      <p className="text-xl">
        calculate initial freight with given latest market situation
      </p>
    </div>
  );
};

export default Card;
