import React from "react";

const SearchFilter = ({ setIsOpen }) => {
  const data = [
    {
      label: "Name",
      values: ["Any", "Specified"],
    },
    {
      label: "Flag",
      values: ["Any", "Other"],
    },
    {
      label: "Type",
      values: ["Tanker", "Drybulk", "General Cargo", "LPG", "Other"],
    },
    {
      label: "Age",
      values: ["Any", "NewBuild", ">5", "6-10", ">15"],
    },
    {
      label: "Navigational Status",
      values: ["Any", "Streaming", "Loading", "Unloading", "Ballast"],
    },
    {
      label: "Gears",
      values: ["Any", "Geared", "Gearless", "Others"],
    },
  ];

  return (
    <div className="fixed w-full h-screen flex justify-center items-center top-0 left-0 z-50">
      <div
        className="fixed w-full h-screen top-0 left-0 bg-black opacity-50"
        onClick={() => setIsOpen(false)}
      ></div>
      <div className="w-[70%] h-[70%] bg-secondaryColor shadow-md z-10 rounded p-10">
        <div className="flex items-center justify-between">
          <div>
            <button className="px-14 py-2 rounded bg-primaryColor text-white mr-3">
              Ship
            </button>
            <button className="px-14 py-2 rounded bg-primaryColor text-white">
              Owner
            </button>
          </div>
          <button className="px-14 py-2 rounded bg-primaryColor text-white">
            Clear
          </button>
        </div>
        <div className="mt-[15px] border p-5 rounded">
          {data.map((item, i) => (
            <div className="grid grid-cols-7 gap-3 my-2" key={i}>
              <div className="col-span-2">{item.label}</div>
              {item.values.map((value, index) => (
                <button
                  className="col-span-1 p-1 rounded text-left px-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  key={index}
                >
                  {value}
                </button>
              ))}
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center mt-[15px]">
          <button className="px-10 py-2 rounded bg-primaryColor text-white w-[calc(75%_-_10px)] mr-[10px]">
            Cancel
          </button>
          <button className="px-10 py-2 rounded bg-primaryColor text-white w-[25%]">
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
