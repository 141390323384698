import React from "react";
import Layout from "../../Layout";

const NotFound = () => {
  return (
    <>
      <Layout title="Global Cargo Corporation (GCC)">
        <div className="">
          <div className="w-full h-[calc(100vh_-_195px)] flex justify-center items-center">
            <h1 className="text-secondaryColor text-7xl">Page Not Found!</h1>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;
