import React from "react";
import Sidebar from "../Sidebar";
import LatestNews from "./LatestNews";

const Layout = ({ title, children }) => {
  return (
    <div className="w-full">
      <Sidebar />

      <div className="fixed top-0 right-0 h-screen w-[calc(100%_-_200px)]">
        <img
          src="./assets/images/background.jpg"
          alt="background"
          className="w-full h-full object-cover"
        />
        <div className="bg-black fixed top-0 right-0 h-screen w-[calc(100%_-_200px)] opacity-80"></div>
      </div>
      <div className="float-right p-[15px] relative w-[calc(100%_-_200px)] overflow-hidden overflow-y-scroll">
        <div className="h-[85px] bg-secondaryColor flex justify-center items-center text-white text-4xl rounded">
          {title}
        </div>
        <div className="w-full h-[calc(100vh_-_130px)] mt-[15px] rounded overflow-hidden">
          <div className="flex flex-col h-full justify-between">
            {children}
            <LatestNews />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
