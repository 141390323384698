import React from "react";

const Buttons = () => {
  return (
    <div className="w-full grid grid-cols-6 gap-[5px]">
      <button className="bg-primaryColor text-white p-4 rounded">All</button>
      <button className="bg-primaryColor text-white p-4 rounded">
        Positions
      </button>
      <button className="bg-primaryColor text-white p-4 rounded">
        Negotiations/offers
      </button>
      <button className="bg-primaryColor text-white p-4 rounded">
        Market News
      </button>
      <button className="bg-primaryColor text-white p-4 rounded">
        Direct Messages
      </button>
      <button className="bg-primaryColor text-white p-4 rounded">
        Agents/Vetting
      </button>
    </div>
  );
};

export default Buttons;
