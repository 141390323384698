import React, { useState } from "react";
import TableHeader from "../MyOffers/OfferedShips/Recieved/TableHeader";
import TableBody from "../MyOffers/OfferedShips/Recieved/TableBody";
import Layout from "../../Layout";
import SearchFilter from "../../common/SearchFilter";

const Search = () => {
  const [activeComponent, setActiveComponent] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const data = [
    "Short List",
    "Ship Search",
    "Owner Search",
    "Shipper Search",
    "My Map",
    "Shipping Schedule",
  ];

  return (
    <Layout title={"Search"}>
      {isOpen && <SearchFilter setIsOpen={setIsOpen} />}
      <div>
        <div className="grid grid-cols-6 content-start relative gap-2">
          {data.map((item, i) => (
            <button
              key={i}
              className={`bg-primaryColor text-white py-3 px-8 rounded mr-2 w-full ${
                activeComponent === item ? "border" : ""
              }`}
              onClick={() => setActiveComponent(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] h-[300px] overflow-hidden overflow-y-scroll mt-[15px]">
          <div className="w-full text-white flex justify-between">
            <div className="">
              <button
                className="py-1 px-5 bg-primaryColor rounded mr-2"
                onClick={() => setIsOpen(true)}
              >
                Search
              </button>
              <button className="py-1 px-5 bg-primaryColor rounded mr-2">
                View
              </button>
            </div>
            <div className="">
              <button className="py-1 px-5 bg-primaryColor rounded mr-2">
                Filters
              </button>
              <button className="py-1 px-5 bg-primaryColor rounded">
                Delete
              </button>
            </div>
          </div>
          <div className="w-full">
            <div className="w-full border-collapse text-white table-auto">
              <TableHeader />
              <TableBody />
            </div>
          </div>
        </div>
        <div className="w-full text-white flex justify-between mt-[15px]">
          <button className="py-2 px-5 bg-primaryColor rounded mr-2 w-[80%]">
            Back
          </button>
          <button className="py-2 px-5 bg-primaryColor rounded w-[20%]">
            Next
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
