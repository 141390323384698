import React from "react";

const Sent = () => {
  return (
    <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] h-[300px] overflow-hidden overflow-y-scroll">
      <div className="w-full text-white text-2xl">No Offers</div>
    </div>
  );
};

export default Sent;
