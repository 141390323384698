import React from "react";
import { useLocation } from "react-router-dom";


const Sidebar = () => {
  const data = [
    {
      path: "/",
      label: "Homepage",
    },
    {
      path: "/shipping_schedule",
      label: "Shipping Schedule",
    },
    {
      path: "/offered-ships",
      label: "Offered Ships",
    },
    {
      path: "/offer-cargo",
      label: "Offer Cargo",
    },
    {
      path: "/live-map",
      label: "Live Map",
    },
    {
      path: "/contracts-monitor",
      label: "Contracts Monitor",
    },
    {
      path: "/market-update",
      label: "Market Update",
    },
    {
      path: "/ready_sail",
      label: "MV Ready Sail",
    },
    {
      path: "/contacts",
      label: "Contacts",
    },
    {
      path: "/search",
      label: "Search",
    },
    {
      path: "/settings",
      label: "Settings",
    },
    {
      path: "/more_features",
      label: "More Features",
    },
  ];
	
  const location = useLocation();

	function changeFont(e) {
    console.log(e.target.value);
		if (e.target.value !== "") {
			document.getElementById("google-font").attributes["href"].value = "https://fonts.googleapis.com/css?family=" + e.target.value;
			document.getElementById("body").style.fontFamily = e.target.value;
		}
  }

  return (
    <div className="w-[200px] h-screen overflow-hidden overflow-y-scroll fixed top-0 left-0 bg-primaryColor p-3">
      <div className="pt-5 pb-10 text-white text-center text-xl">
				<input className="text-black" type="text" id="font-selector" onChange={changeFont}></input>
        <p>Friday</p>
        <p>22 Jul, 2023</p>
        <p>12:19AM</p>
      </div>
      <div className="grid grid-cols-1 gap-2">
        {data.map((item, i) => (
          <a
            key={i}
            href={item.path}
            className={`p-4 text-center text-white rounded w-full float-left text-lg ${
              location.pathname === item.path
                ? "bg-secondaryColor"
                : "bg-primaryColor"
            }`}
          >
            {item.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;

