import React from "react";

const TableBody = () => {
  const data = [
    {
      sr: "Sh.Is",
      inf: "Open",
      Name: "MV MASTER",
      flag: "PNM",
      age: 2,
      deadWeight: "55,000",
      shipVet: 5,
      activity: "Loading",
      dateOpen: "17 December 24",
      connect: "",
    },
    {
      sr: "",
      inf: "",
      Name: "MV RIO MATTA",
      flag: "PNM",
      age: 5,
      deadWeight: "56,000",
      shipVet: 3,
      activity: "Discharging",
      dateOpen: "17 December 24",
      connect: "",
    },
    {
      sr: "MyM",
      inf: "",
      Name: "MV SABRINA",
      flag: "POR",
      age: 7,
      deadWeight: "44,000",
      shipVet: 4,
      activity: "Ballast",
      dateOpen: "18 December 24",
      connect: "",
    },
  ];

  const commonStyleForBody = `py-1 px-2 font-normal rounded`;

  function getStars(rating) {
    let output = [];

    for (let i = 0; i < 5; i++) {
      i < rating ? output.push("filled") : output.push("no");
    }
    return output;
  }

  return (
    <>
      {data.map((item, i) => (
        <div className="flex items-center w-full justify-between" key={i}>
          <div className="w-[4%] bg-primaryColor py-1 px-2 min-h-[26px] rounded">
            {item.sr}
          </div>
          <div className={`${commonStyleForBody} w-[5%] text-center`}>
            {item.inf && (
              <div className="bg-secondaryColor min-h-[26px] rounded">
                {item.inf}
              </div>
            )}
          </div>
          <div className={`${commonStyleForBody} w-[15%] text-left`}>
            {item.Name}
          </div>
          <div className={`${commonStyleForBody} w-[5%] text-center`}>
            {item.flag}
          </div>
          <div className={`${commonStyleForBody} w-[5%] text-center`}>
            {item.age}
          </div>
          <div className={`${commonStyleForBody} w-[14%] text-center`}>
            {item.deadWeight}
          </div>
          <div
            id="shipVet"
            className={`${commonStyleForBody} w-[15%] text-center flex justify-center`}
          >
            {getStars(item.shipVet).map((star, ind) => (
              <div key={ind} className="flex">
                {star === "filled" ? (
                  <i className="fa-solid fa-star text-sm text-secondaryColor mx-[1px]"></i>
                ) : (
                  <i className="fa-regular fa-star text-sm mx-[1px] text-white"></i>
                )}
              </div>
            ))}
          </div>
          <div
            className={`${commonStyleForBody} w-[10%] text-center text-secondaryColor`}
          >
            {item.activity}
          </div>
          <div
            className={`${commonStyleForBody} w-[15%] text-center text-secondaryColor`}
          >
            {item.dateOpen}
          </div>
          <div className={`${commonStyleForBody} w-[10%] text-center`}>
            {item.connect}
          </div>
        </div>
      ))}
    </>
  );
};

export default TableBody;
