import React from "react";

const Navigations = () => {
  return (
    <div className="flex justify-between items-center relative">
      <div>
        <button className="bg-primaryColor text-white py-1 px-5 rounded mr-2">
          Next Unread
        </button>
        <button className="bg-primaryColor text-white py-1 px-5 rounded mr-2">
          Select All
        </button>
        <button className="bg-primaryColor text-white py-1 px-5 rounded">
          Mark as Unread
        </button>
      </div>
      <h6 className="text-white py-1 px-5 rounded-full text-3xl font-semibold absolute left-1/2 -translate-x-1/2">
        Inbox(20)
      </h6>
      <button className="bg-primaryColor text-white py-1 px-5 rounded">
        Filter
      </button>
    </div>
  );
};

export default Navigations;
