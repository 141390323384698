import React from "react";

const ReadMessage = () => {
  return (
    <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] h-[300px] overflow-hidden overflow-y-scroll">
      <div className="w-full">
        <h5 className="text-secondaryColor text-3xl w-max mb-2 font-bold">
          MV SEABEAST is arriving today to Loading port at 1600 hrs
        </h5>
        <p className="text-secondaryColor w-full">
          Date: Friday 15th August 2023
        </p>
        <p className="text-secondaryColor w-full">Status: Read</p>
        <p className="text-xl text-white text-left relative group transition-all w-max max-w-full mt-5">
          MV SEABEAST latest ETA to load port is today at 1600 hrs as per Master
          last updated. The ship will proceed to Holdsupdated. The ship will
          proceed to Holds inspection prior loading, it is planned to load
          50,000 Tons +/-5%inspection prior loading, it is planned to load
          50,000 Tons +/-5% Load Port Agent will keep posting updatesLoad Port
          Agent will keep posting update
        </p>
      </div>
    </div>
  );
};

export default ReadMessage;
