import React from "react";

const TableHeader = () => {
  const commonStyleForHead = `bg-primaryColor py-1 px-2 font-normal rounded`;
  return (
    <div className="flex items-center w-full justify-between mb-3">
      <div className="w-[4%]"></div>
      <div className={`${commonStyleForHead} w-[5%] text-center`}>Inf</div>
      <div className={`${commonStyleForHead} w-[15%] text-left`}>Name</div>
      <div className={`${commonStyleForHead} w-[5%] text-center`}>Flag</div>
      <div className={`${commonStyleForHead} w-[5%] text-center`}>Age</div>
      <div className={`${commonStyleForHead} w-[14%] text-center`}>
        Deadweight
      </div>
      <div className={`${commonStyleForHead} w-[15%] text-center`}>
        Ship Vet
      </div>
      <div className={`${commonStyleForHead} w-[10%] text-center`}>
        Activity
      </div>
      <div className={`${commonStyleForHead} w-[15%] text-center`}>
        Date Open
      </div>
      <div className={`${commonStyleForHead} w-[10%] text-center`}>Connect</div>
    </div>
  );
};

export default TableHeader;
