import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Recieved = () => {
  return (
    <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] h-[300px] overflow-hidden overflow-y-scroll">
      <div className="w-full text-white flex justify-between">
        <div className="">
          <button className="py-1 px-5 bg-primaryColor rounded mr-2">
            Search
          </button>
          <button className="py-1 px-5 bg-primaryColor rounded mr-2">
            View
          </button>
        </div>
        <div className="">
          <button className="py-1 px-5 bg-primaryColor rounded mr-2">
            Filters
          </button>
          <button className="py-1 px-5 bg-primaryColor rounded">Delete</button>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full border-collapse text-white table-auto">
          <TableHeader />
          <TableBody />
        </div>
      </div>
    </div>
  );
};

export default Recieved;
