import React from "react";
import Layout from "../../Layout";
import Card from "./Card";

const MoreFeatures = () => {
  return (
    <>
      <Layout title="More Features">
        <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] h-[calc(100vh_-_225px)] overflow-hidden overflow-y-scroll mt-[15px]">
          <div className="w-full grid grid-cols-3 gap-[25px] content-start">
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MoreFeatures;
