import React from "react";
import SelectInput from "../../../common/SelectInput";
import OnlySelect from "../../../common/OnlySelect";

const Label = ({ labelName }) => {
  return <p className="w-[200px] text-xl">{labelName}</p>;
};

const Basic = () => {
  const data = [
    {
      label: "Quantity",
      inputType: "select",
      width: "full",
    },
    {
      label: "Tolerance",
      inputType: "select3",
      width: "full",
    },
    {
      label: "Product",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Packaging",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Load Port",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Discharge Port",
      inputType: "select2",
      width: "full",
    },
    {
      label: "Loading Rate",
      inputType: "select",
      width: "full",
    },
    {
      label: "MT",
      inputType: "onlyselect",
      width: "full",
    },
    {
      label: "Discharge Rate",
      inputType: "select2",
      width: "full",
    },
    {
      label: "MT",
      inputType: "onlyselect",
      width: "full",
    },
    {
      label: "Laychan",
      inputType: "select",
      width: "full",
    },
    {
      label: "To",
      inputType: "select",
      width: "full",
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-x-20 gap-y-5 content-start h-[430px] overflow-hidden overflow-y-scroll">
      {data.map((item, i) => (
        <div className="flex items-center text-white my-0 col-span-1" key={i}>
          {item.inputType === "onlyselect" ? (
            <div className="w-[200px]">
              <OnlySelect />
            </div>
          ) : (
            <Label labelName={item.label} />
          )}
          <div className="flex justify-between items-center flex-1">
            {item.inputType !== "onlyselect" && (
              <SelectInput
                fullWidth={item.inputType === "select2" ? false : true}
              />
            )}

            {item.inputType === "select2" && <OnlySelect />}
            {item.inputType === "select3" && <OnlySelect />}
            {item.inputType === "select3" && <OnlySelect />}
            {item.inputType === "onlyselect" && <OnlySelect fullWidth />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Basic;
