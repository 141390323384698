import React from "react";

const AllMessages = ({ data }) => {
  return (
    <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] h-[300px] overflow-hidden overflow-y-scroll">
      {data.map((item, i) => (
        <div className="grid grid-cols-1 gap-[5px] w-full" key={i}>
          <h5 className="text-white text-2xl border-b w-max pb-2 mb-3">
            {item.date}
          </h5>
          {item.messages.map((m, ind) => (
            <button
              className="text-xl text-white text-left relative group transition-all py-1 px-2 w-max max-w-full"
              key={ind}
            >
              <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-white opacity-20 group-hover:block hidden rounded"></div>
              {m}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AllMessages;
