import React from "react";

const CargoInformation = () => {
  const data = [
    {
      label: "Product Name",
      value: "Granular Urea",
    },
    {
      label: "Physical state",
      value: "Solid",
    },
    {
      label: "CAS number",
      value: "57-13-6",
    },
    {
      label: "Color",
      value: "White",
    },
    {
      label: "Product type",
      value: "Solid",
    },
    {
      label: "Physical condition",
      value:
        "Free flowing free of harmful substancesfree of harmful substances",
    },
    {
      label: "Stowage FactorFactor",
      value: "SF 50-52 WOG",
    },
    {
      label: "Melting/freezing pointpoint",
      value: "132.8°C",
    },
    {
      label: "Weather ImpactImpact",
      value: "Humidity & waterwate",
    },
    {
      label: "Density",
      value: "1.335 g/cm³ [20°C (68°F)]",
    },
    {
      label: "Special precautions for user",
      value:
        "always transport in closed containers that are upright and secureupright and secure",
      width: "full",
    },
    {
      label: "Possibility of hazardous reactionshazardous reactions",
      value:
        "Under normal conditions of storage and use, hazardous reactions will not occur",
      width: "full",
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-x-20 gap-y-5 h-[430px] content-start overflow-hidden overflow-y-scroll">
      {data.map((item, i) => (
        <div
          key={i}
          className={`flex items-center text-white my-0 ${
            item.width === "full" ? "col-span-2" : "col-span-1"
          }`}
        >
          <div
            className={`${
              item.width === "full" ? "w-[350px]" : "w-[220px]"
            } flex justify-between items-center`}
          >
            <p className="text-xl">{item.label}</p> <span>:</span>
          </div>
          <div className="flex-1 px-4">
            <p className="text-xl font-bold">{item.value}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CargoInformation;
