import React, { useState } from "react";
import Layout from "../../Layout";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

const ShippingSchedule = () => {
  const [activeComponent, setActiveComponent] = useState("");

  const data = [
    "Schedule 1",
    "Live Shipping schedule",
    "Add + Schedule",
    "Manual Schedule",
    "Search Schedule",
    "Log",
  ];

  return (
    <>
      <Layout title="Shipping Schedule">
        <div className="grid grid-cols-1 gap-[15px]">
          <div className="w-full relative flex justify-center items-center py-2 px-5 text-3xl text-white rounded overflow-hidden">
            <div className="w-full absolute h-full top-0 left-0 right-0 bottom-0 bg-white opacity-20"></div>
            Schedule 1 (Al Jubail, Saudi Arabia)
          </div>
          <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] max-h-[625px] overflow-hidden overflow-y-scroll">
            <div className="grid grid-cols-6 content-start relative gap-2">
              {data.map((item, i) => (
                <button
                  key={i}
                  className={`bg-primaryColor text-white py-3 px-8 rounded mr-2 w-full ${
                    activeComponent === item ? "border" : ""
                  }`}
                  onClick={() => setActiveComponent(item)}
                >
                  {item}
                </button>
              ))}
            </div>
            <div className="w-full">
              <div className="w-full border-collapse text-white table-auto">
                <TableHeader />
                <TableBody />
              </div>
            </div>
          </div>
          <div className="w-full text-white flex justify-between">
            <button className="py-2 px-5 bg-primaryColor rounded mr-2 w-[80%]">
              Back
            </button>
            <button className="py-2 px-5 bg-primaryColor rounded w-[20%]">
              Next
            </button>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ShippingSchedule;
