import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./components/pages/Home";
import OfferedShips from "./components/pages/MyOffers/OfferedShips";
import OfferCargo from "./components/pages/MyOffers/OfferCargo";
import NotFound from "./components/pages/NotFound";
import Search from "./components/pages/Search";
import ReadySail from "./components/pages/ReadySail";
import ShippingSchedule from "./components/pages/ShippingSchedule";
import MoreFeatures from "./components/pages/MoreFeatures";
import LiveMap from "./components/pages/LiveMap";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/shipping_schedule",
      element: <ShippingSchedule />,
    },
    {
      path: "/offered-ships",
      element: <OfferedShips />,
    },
    {
      path: "/live-map",
      element: <LiveMap />,
    },
    {
      path: "/offer-cargo",
      element: <OfferCargo />,
    },
    {
      path: "/search",
      element: <Search />,
    },
    {
      path: "/ready_sail",
      element: <ReadySail />,
    },
    {
      path: "/more_features",
      element: <MoreFeatures />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
