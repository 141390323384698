import React from "react";

const LatestNews = () => {
  return (
    <div className="w-full bg-primaryColor rounded py-3 px-6">
      <div className="text-white flex items-center overflow-hidden">
        <b className="text-xl mr-2">Latest News:</b>
        <div className="flex-1 overflow-hidden">
          <div className="flex-1 scrollingEffect">
            <span className="text-xl">
              Capsize: It was mostly another uninspiring week, with rates on the
              main volume routes out of Brazil and remaining subdued. Ballasters
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
