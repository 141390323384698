import React, { useState } from "react";
import Layout from "../../Layout";

const ReadySail = () => {
  const [activeComponent, setActiveComponent] = useState("");

  const data = ["Profile", "Position", "Rating", "Certification", "History"];
  const data1 = [
    {
      label: "IMO",
      value: "9629366",
    },
    {
      label: "Name",
      value: "READYSAIL",
    },
    {
      label: "Vessel Type – Generic",
      value: "Tanker",
    },
    {
      label: "Vessel Type – Detailed",
      value: "Crude Oil Tanker",
    },
    {
      label: "Product type",
      value: "Solid",
    },
    {
      label: "Physical condition",
      value:
        "Free flowing free of harmful substancesfree of harmful substances",
    },
    {
      label: "Stowage FactorFactor",
      value: "SF 50-52 WOG",
    },
    {
      label: "Melting/freezing pointpoint",
      value: "132.8°C",
    },
    {
      label: "Weather ImpactImpact",
      value: "Humidity & waterwate",
    },
    {
      label: "Density",
      value: "1.335 g/cm³ [20°C (68°F)]",
    },
    {
      label: "Special precautions for user",
      value:
        "always transport in closed containers that are upright and secureupright and secure",
      width: "full",
    },
    {
      label: "Possibility of hazardous reactionshazardous reactions",
      value:
        "Under normal conditions of storage and use, hazardous reactions will not occur",
      width: "full",
    },
  ];

  return (
    <Layout title={"MV ReadySail"}>
      <div>
        <div className="grid grid-cols-6 content-start relative gap-2">
          {data.map((item, i) => (
            <button
              key={i}
              className={`bg-primaryColor text-white py-3 px-8 rounded mr-2 w-full ${
                activeComponent === item ? "border" : ""
              }`}
              onClick={() => setActiveComponent(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <div className="w-full rounded border border-primaryColor p-8 grid grid-cols-1 gap-[25px] h-[630px] overflow-hidden overflow-y-scroll mt-[15px]">
          <div className="w-full relative flex justify-center items-center py-0 px-5 text-3xl text-white rounded overflow-hidden">
            <div className="w-full absolute h-full top-0 left-0 right-0 bottom-0 bg-white opacity-20"></div>
            Build 28.6.22 (Age 2). Panama
          </div>
          <div className="grid grid-cols-2 gap-x-20 gap-y-5 content-start overflow-hidden overflow-y-scroll">
            {data1.map((item, i) => (
              <div
                key={i}
                className={`flex items-center text-white my-0 ${
                  item.width === "full" ? "col-span-2" : "col-span-1"
                }`}
              >
                <div
                  className={`${
                    item.width === "full" ? "w-[350px]" : "w-[220px]"
                  } flex justify-between items-center`}
                >
                  <p className="text-xl">{item.label}</p> <span>:</span>
                </div>
                <div className="flex-1 px-4">
                  <p className="text-xl font-bold">{item.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full text-white flex justify-between mt-[15px]">
          <button className="py-2 px-5 bg-primaryColor rounded mr-2 w-[80%]">
            Back
          </button>
          <button className="py-2 px-5 bg-primaryColor rounded w-[20%]">
            Next
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default ReadySail;
