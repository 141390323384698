import React from "react";

const OnlySelect = ({ small, fullWidth }) => {
  return (
    <select
      className={`bg-black text-secondaryColor h-[35px] ${
        small ? "w-[80px]" : `${fullWidth ? "w-full" : "w-[150px]"}`
      } px-3`}
    >
      <option>MT</option>
    </select>
  );
};

export default OnlySelect;
